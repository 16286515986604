.custom-loader {
  width: 100px;
  height: 100px;
  display: grid;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 40%, #000 41%);
  background: linear-gradient(0deg, #fff80 50%, #fffFF 0) center/8px 100%,
    linear-gradient(90deg, #fff40 50%, #fffBF 0) center/100% 8px;
  background-repeat: no-repeat;
  animation: s3 0.5s infinite steps(12);
}
.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.custom-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
