::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
    border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
